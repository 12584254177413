import {css, html} from 'lit';
import {customElement, property} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import {snakeCase} from '../../util/strings';

import {BaseAccordionItemElement} from './base-accordion-item';

import {type Periode} from '@/js/types/models/cursusreeks';

const longTitleDipslayOn = 993; // in pixels; matches materialize $medium-screen-up

@customElement('periode-item')
export class PeriodeItemElement extends BaseAccordionItemElement {
  @property({type: Object})
  public accessor periode: Periode | null = null;

  protected override _defaultTitle = '';

  protected override get _title() {
    return snakeCase(this.periode?.Naam ?? this._defaultTitle);
  }

  static styles = [
    BaseAccordionItemElement.baseStyles,
    css`
      :host {
        margin-bottom: 0;
      }
      .title-link {
        background-color: var(--periode-collapsible-color);
        color: var(--white);
        justify-content: space-between;
        padding: 1rem;
      }
      .accordion-header {
        border: 0;
        border-bottom: 1px solid var(--white);
      }
      .periode-titel {
        display: none;
        font-weight: 500;
        font-size: 1.25rem;
      }
      .periode-subtitel {
        max-width: 50%;
        padding-left: 10px;
        text-align: right;
      }
      .small-font {
        font-size: 1rem;
      }
      @media only screen and (max-width: ${longTitleDipslayOn - 0.001}px) {
        .korte-titel {
          display: block !important
        }
      }
      @media only screen and (min-width: ${longTitleDipslayOn}px) {
        .lange-titel {
          display: block !important
        }
      }
    `,
  ];

  protected override _headerTitleTemplate() {
    return html`
      <span class="periode-titel lange-titel">
        ${this.periode?.Naam} - ${this.periode?.dagenOmschrijving}
      </span>
      <div class="periode-titel korte-titel">
        <span>${this.periode?.Naam}</span><br />
        <span class="small-font">${this.periode?.dagenOmschrijving}</span>
      </div>
      <span class="periode-titel periode-subtitel lange-titel">
        <slot name="subtitel"></slot>
      </span>
      <span class="periode-titel periode-subtitel korte-titel">
        <slot name="subtitel-kort"></slot>
      </span>
    `;
  }
}
